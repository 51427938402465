import React, { Component } from "react";
import GridItem from "../../Components/ProductGridItem";
import ListItem from "../../Components/ProductListItem";
import AppConfig from "../../Config";
import labels from "../../Domain/ProductTree";
import ListingPageFactory from "../../Components/ListingPageFactory";

const moduleConfig = AppConfig["Templates/ProductListingPage"] || {};

export default ListingPageFactory({
  layoutType: moduleConfig.layoutType,
  itemLimit: moduleConfig.itemLimit,
  cssPrefix: "products",
  hasBreadcrumbs: moduleConfig.breadcrumbs,
  hasSearching: moduleConfig.searching,
  css: moduleConfig.css,
  Item: moduleConfig.layoutType === "grid" ? GridItem : ListItem,
  pathPrefix: "/products",
  detailPathPrefix: "/product",
  fetchAction: "fetchProducts",
  labels,
  sortType: "products"
});
