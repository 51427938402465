import React, { Component } from "react";
import styled from "styled-components";
import AppConfig from "../../Config";
import SortOption from "../SortOption";

const moduleConfig = AppConfig["Component/SortingGroup"];

class SortingGroup extends Component {
  render() {
    let { sort, type } = this.props;
    let options = moduleConfig[`${type}Options`];

    return (
      <Wrapper>
        {options.map((option, index) => (
          <SortOption key={index} option={option} sort={sort} />
        ))}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${AppConfig["Component/SortingGroup"].css || ""}
`;

export default SortingGroup;
