import React from "react";
import _ from "lodash";

class ItemBrowser extends React.Component {
  static defaultProps = {
    limit: 20,
    label: ""
  };

  constructor(props) {
    super(props);
    this.prevFetchParam = {};
    this.state = {
      mounted: false,
      loading: false,
      result: null
    };
  }

  componentDidMount() {
    this.setState({ mounted: true });
    this._fetchItems();
  }

  componentDidUpdate(prevProps, prevState, _) {
    let { limit, label, search, query } = this.props;
    let currFetchParam = getFetchParams({ label, limit, search }, query);
    function isShallowEqual(a, b) {
      return Object.keys(a).every(k => a[k] === b[k]);
    }
    !isShallowEqual(currFetchParam, this.prevFetchParam) && this._fetchItems();
  }

  render() {
    let { query } = this.props;
    let { mounted, result, loading } = this.state;
    if (!mounted) {
      return this.props.children({
        mounted,
        loading,
        data: [],
        page: {
          pages: [],
          current: -1,
          go: page => this._goToNextUrl({ page })
        },
        sort: {
          value: "",
          go: sort => this._goToNextUrl({ sort, resetPage: true })
        },
        search: {
          value: "",
          go: search => this._goToNextUrl({ search, resetPage: true })
        }
      });
    }

    let queryParam = parseQueryParam(query);
    return this.props.children({
      mounted,
      loading,
      data: (result && result.results) || [],
      page: {
        pages:
          (result &&
            this._convertCountToArray(result.count, this.props.limit)) ||
          [],
        current: queryParam.page || 1,
        go: page => this._goToNextUrl({ page })
      },
      sort: {
        value: queryParam.sort || "",
        go: sort => this._goToNextUrl({ sort, resetPage: true })
      },
      search: {
        value: queryParam.search || "",
        go: search => this._goToNextUrl({ search, resetPage: true })
      }
    });
  }

  _goToNextUrl = ({ page, search, sort, resetPage }) => {
    function addOptionIfDefined(base, key, value) {
      if (value !== undefined) {
        base[key] = value;
      }
    }

    if (resetPage) {
      page = 1;
    }

    let overrideParam = {};
    addOptionIfDefined(overrideParam, "page", page);
    addOptionIfDefined(overrideParam, "search", search);
    addOptionIfDefined(overrideParam, "sort", sort);

    let nextQueryParam = {
      ...parseQueryParam(),
      ...overrideParam
    };

    let nextQueryString = "";
    for (let key in nextQueryParam) {
      if (nextQueryParam[key] !== "") {
        nextQueryString += `${key}=${nextQueryParam[key]}&`;
      }
    }

    if (nextQueryString !== "") {
      nextQueryString = "?" + nextQueryString.slice(0, -1);
    }

    let { navPush } = this.props;
    navPush(`${window.location.pathname}${nextQueryString}`);
  };

  _fetchItems = () => {
    let { limit, label, fetchItems, search, query } = this.props;
    let fetchParam = getFetchParams({ label, limit, search }, query);
    console.log("fetchParam", fetchParam);

    this.prevFetchParam = { ...fetchParam };
    this.setState({
      loading: true
    });

    fetchItems(fetchParam)
      .then(result => {
        console.log("done fetch");
        this.setState({ result, loading: false });
      })
      .catch(err => {
        console.warn(err);
        this.setState({ loading: false });
      });
  };

  _convertCountToArray = (count, limit) => {
    if (count === 0) {
      return [];
    }

    return _.range(1, Math.ceil(count / limit) + 1);
  };
}

function parseQueryParam(query) {
  if (query === undefined) {
    query = window.location.search;
  }
  if (!query || query.indexOf("?")) {
    return {};
  }

  return query
    .split("?")[1]
    .split("&")
    .map(query => query.split("="))
    .reduce((acc, [key, value]) => {
      if (key === "page") {
        value = Number(value);
      }

      acc[key] = value;
      return acc;
    }, {});
}

function getFetchParams({ label, limit, search }, query) {
  let { page, sort } = parseQueryParam(query);
  let offset = ((page || 1) - 1) * limit;
  let fetchParams = {
    limit,
    offset,
    search,
    sort,
    label
  };
  return fetchParams;
}

export function getPageNumber(query) {
  return parseQueryParam(query).page;
}

export function getSortString(query) {
  return parseQueryParam(query).sort;
}

export function getSearchString(query) {
  return parseQueryParam(query).search;
}

export default ItemBrowser;
