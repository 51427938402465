import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import categoryTree from "../../Domain/ProductTree";
import * as Icon from "../Icon";

const Wrapper = styled.div`
  display: flex;
  height: 30px;
`;

const SearchButton = styled.div`
  cursor: pointer;
  display: flex;
  padding: 5px;
  align-items: center;
  justify-content: center;
  min-width: 36px;
  color: ${props => (props.selected ? "white" : "#999999")};
  border-radius: 0px 5px 5px 0px;
  background-color: #6b6b6b;

  :hover {
    background-color: #525252;
  }
`;

const SearchInput = styled.input`
  padding: 5px;
  border-radius: 5px 0px 0px 5px;
  outline: none;
  border: none;
`;

class Searching extends React.Component {
  state = {
    searchInput: ""
  };
  render() {
    let { searchInput } = this.state;
    let { onSubmit, search } = this.props;
    return (
      <Wrapper>
        <SearchInput
          type="text"
          placeholder="search..."
          onChange={e => this.setState({ searchInput: e.target.value })}
        />
        <SearchButton
          onClick={() => {
            search.go(searchInput);
            onSubmit(searchInput);
          }}
        >
          <Icon.Search size={20} color={"#b7b6b6"} />
        </SearchButton>
      </Wrapper>
    );
  }
}

export default Searching;
