import React, { Component } from "react";
import styled from "styled-components";
import * as Icon from "../Icon";
import AppConfig from "../../Config";

const moduleConfig = AppConfig["Component/SortOption"] || {};

class SortOption extends Component {
  constructor(props) {
    super(props);
    this.ascending = true;
    let prefix = props.option.value[0];
    if (prefix === "+") {
      this.fixedSort = true;
      this.ascending = true;
    } else if (prefix === "-") {
      this.fixedSort = true;
      this.ascending = false;
    }
  }

  render() {
    let { option } = this.props;

    return (
      <Wrapper active={this._isActive()} onClick={this._onClick}>
        {option.label}
        {this.ascending ? (
          <Icon.ArrowDownward size="16" color="lightgray" />
        ) : (
          <Icon.ArrowUpward size="16" color="lightgray" />
        )}
      </Wrapper>
    );
  }

  _isActive = () => {
    let { option, sort } = this.props;
    if (this.fixedSort) {
      return sort.value === option.value;
    } else {
      return sort.value && sort.value.indexOf(option.value) === 1;
    }
  };

  _onClick = () => {
    let { option, sort } = this.props;
    if (this.fixedSort) {
      sort.go(option.value);
    } else {
      if (this._isActive()) {
        this.ascending = !this.ascending;
      }
      sort.go((this.ascending ? "+" : "-") + option.value);
    }
  };
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 5px;
  padding: 5px 10px;
  border: 1px solid lightgray;
  border-radius: 18px;
  background-color: ${props =>
    props.active ? `${moduleConfig.color}` : "white"};
  color: ${props => (props.active ? "white" : "gray")};
  min-width: 80px;
  font-size: 14px;
  cursor: pointer;

  ${moduleConfig.css || ""}
`;

export default SortOption;
