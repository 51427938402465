import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withPage } from "../../Page";
import ActionCreator from "../../ActionCreator";
import FilterPanel from "../../Components/FilterPanel";
import ItemBrowser from "../../Components/ItemBrowser";
import Paging from "../../Components/Paging";
import SortingGroup from "../../Components/SortingGroup";
import Breadcrumbs from "../../Components/Breadcrumbs";
import Searching from "../../Components/Searching";

const ListingPageFactory = ({
  layoutType = "grid",
  itemLimit = 60,
  cssPrefix = "items",
  css = "",
  Item,
  pathPrefix,
  detailPathPrefix,
  fetchAction,
  labels,
  sortType,
  FilterPanelComp,
  PagingComp,
  SortingGroupComp,
  hasBreadcrumbs = true,
  hasSearching = true,
  BreadcrumbsComp,
  SearchingComp
}) => {
  if (!Item) {
    throw "[rev-cms/ListPageFactory] Item not passed, this should be a component";
  }

  if (!pathPrefix) {
    throw '[rev-cms/ListPageFactory] pathPrefix not passed, this should be something like "/products"';
  }

  if (!detailPathPrefix) {
    throw '[rev-cms/ListPageFactory] detailPathPrefix not passed, this should be something like "/product"';
  }

  if (!fetchAction) {
    throw '[rev-cms/ListPageFactory] fetchAction not passed, this should be something like "fetchProducts"';
  }

  if (!labels) {
    throw "[rev-cms/ListPageFactory] labels not passed! please refer to src/Domain/ProductTree for a valid label definition";
  }

  if (!sortType) {
    throw '[rev-cms/ListPageFactory] sortType not passed, this should be something like "products"';
  }

  if (FilterPanelComp === undefined) {
    FilterPanelComp = FilterPanel;
  }

  if (PagingComp === undefined) {
    PagingComp = Paging;
  }

  if (SortingGroupComp === undefined) {
    SortingGroupComp = SortingGroup;
  }

  if (hasBreadcrumbs) {
    BreadcrumbsComp =
      BreadcrumbsComp === undefined ? Breadcrumbs : BreadcrumbsComp;
  }

  if (hasSearching) {
    SearchingComp = SearchingComp === undefined ? Searching : SearchingComp;
  }

  class ListingPage extends Component {
    state = {
      searchInput: ""
    };
    render() {
      let { searchInput } = this.state;
      let { categoryId, appActions, navActions } = this.props;

      return (
        <Wrapper>
          <div className={`${cssPrefix}-filter`}>
            <FilterPanel
              labels={labels}
              activeFilter={categoryId}
              onFilterItemClick={updateLabel =>
                navActions.push(`${pathPrefix}/${updateLabel}`)
              }
            />
          </div>

          <ItemBrowser
            label={categoryId}
            navPush={navActions.push}
            limit={itemLimit}
            fetchItems={appActions[fetchAction]}
            search={searchInput}
          >
            {({ mounted, loading, data, page, sort, search }) => {
              return (
                <div className={cssPrefix + "-content"}>
                  {BreadcrumbsComp && (
                    <div className={cssPrefix + "-breadcrumbs"}>
                      <BreadcrumbsComp
                        pathPrefix={pathPrefix}
                        label={categoryId}
                      />
                    </div>
                  )}

                  {SearchingComp && (
                    <div className={cssPrefix + "-search"}>
                      <Searching
                        onSubmit={searchInput => this.setState({ searchInput })}
                        search={search}
                      />
                    </div>
                  )}

                  <div className={cssPrefix + "-toolbar"}>
                    {SortingGroupComp && sort && (
                      <div className={cssPrefix + "-sort"}>
                        <SortingGroupComp sort={sort} type={sortType} />
                      </div>
                    )}
                  </div>

                  <div className={cssPrefix + "-collection"}>
                    {data &&
                      data.map(d => (
                        <Item
                          onClick={() =>
                            navActions.push(`${detailPathPrefix}/?id=${d.id}`)
                          }
                          key={d.id}
                          item={d}
                        />
                      ))}
                  </div>

                  {PagingComp && page.pages && (
                    <div className={cssPrefix + "-pager"}>
                      <PagingComp page={page} />
                    </div>
                  )}
                </div>
              );
            }}
          </ItemBrowser>
        </Wrapper>
      );
    }
  }

  const Wrapper = styled.div`
    display: flex;
    width: 100%;
    background-color: #e6e6e6;
    flex-direction: row-reverse;

    & > .${cssPrefix}-filter {
      box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
    }

    & > .${cssPrefix}-content {
      display: flex;
      flex-direction: column;
      padding: 10px;
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;

      .${cssPrefix}-breadcrumbs {
      }

      .${cssPrefix}-toolbar {
        display: flex;
        align-items: center;
      }

      .${cssPrefix}-search {
        padding: 10px;
      }

      .${cssPrefix}-sort {
      }

      .${cssPrefix}-collection {
        ${layoutType === "grid" &&
          `
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      `}
      }

      .${cssPrefix}-pager {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    ${css || ""}
  `;

  return withPage(
    connect(
      (state, ownProps) => ({
        categoryId: ownProps.pageContext.categoryId
      }),
      ActionCreator
    )(ListingPage)
  );
};

export default ListingPageFactory;
