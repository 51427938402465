import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import * as Widget from "../Widget2";
import ActionCreator from "../../ActionCreator";
import * as Icon from "../Icon";

const PageNumber = styled.div`
  cursor: pointer;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 36px;
  min-height: 36px;
  color: ${props => (props.selected ? "white" : "#999999")};
  border-radius: 18px;
  border: 2px solid #999999;
  background-color: ${props => (props.selected ? "#999999" : "transparent")};

  :hover {
    background-color: #d2d2d2;
  }
`;

class Paging extends React.Component {
  render() {
    let { page } = this.props;
    return (
      <Widget.Row style={{ justifyContent: "center", alignItems: "center" }}>
        {page.current == 1 ? (
          <div style={{ width: 24 }} />
        ) : (
          <Icon.KeyboardArrowLeft
            onClick={() => page.go(page.current - 1)}
            color={"#999999"}
            style={{ cursor: "pointer" }}
          />
        )}

        {page.pages &&
          page.pages.map(p => (
            <PageNumber
              key={p}
              onClick={() => page.go(p)}
              selected={page.current === p}
            >
              {p}
            </PageNumber>
          ))}
        {page.current == page.pages.length ? (
          <div style={{ width: 24 }} />
        ) : (
          <Icon.KeyboardArrowRight
            onClick={() => page.go(page.current + 1)}
            color={"#999999"}
            style={{ cursor: "pointer" }}
          />
        )}
      </Widget.Row>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    //
  }),
  ActionCreator
)(Paging);
